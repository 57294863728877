import { default as _91id_93VHUgkb5zIKMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexWCFcssEEyXMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_933wgQaEGyl4Meta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexivKqIklvmkMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsdvcxSjda1NMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsIYRzodPGNbMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexgW1JScMtbiMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexjgi9fOI3s5Meta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardS0o3ZMpLYGMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceEIiGARPlc5Meta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/entrance.vue?macro=true";
import { default as indexpyfl7P5LYfMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/index.vue?macro=true";
import { default as loginuiUZi7CaHjMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/login.vue?macro=true";
import { default as indexWFbeXABseeMeta } from "/home/forge/merchants.smake.com/releases/v1.24.5/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93VHUgkb5zIKMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWCFcssEEyXMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexWCFcssEEyXMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_933wgQaEGyl4Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexivKqIklvmkMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsdvcxSjda1NMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsIYRzodPGNbMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexgW1JScMtbiMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjgi9fOI3s5Meta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexjgi9fOI3s5Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardS0o3ZMpLYGMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardS0o3ZMpLYGMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceEIiGARPlc5Meta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: loginuiUZi7CaHjMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: loginuiUZi7CaHjMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.24.5/pages/index.vue").then(m => m.default || m)
  }
]